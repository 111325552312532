<template>
  <center-screen background-color="secondary">
    <div class="d-flex">
      <v-icon color="error" x-large>mdi-alert-circle</v-icon>
      <p class="ma-3">Oops! The page you are looking for might have been removed or is temporarily unavailable.</p>
    </div>

    <template v-slot:actions>
      <v-btn text color="primary" tile depressed @click="goToRegister">
        {{ $t("login.register") }}
      </v-btn>
      <v-divider vertical />
      <v-btn text color="primary" tile depressed @click="goToLoginByEmail">
        {{ $t("login.loginByEmail") }}
      </v-btn>
      <v-btn text color="primary" tile depressed @click="goToLoginByPhone">
        {{ $t("login.loginByPhone") }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        tile
        depressed
        @click="$$router.back()"
      >
        {{ $t("general.back") }}
      </v-btn>
    </template>
  </center-screen>
</template>

<script>
import Copyright from "@/common/components/Copyright";
import CenterScreen from "@/common/layouts/CenterScreen";

export default {
  name: "Page404",
  components: { Copyright, CenterScreen },
  methods: {
    goToRegister() {
      this.$$router.push({ name: "register" });
    },
    goToLoginByEmail() {
      this.$$router.push({ name: "alt-login" });
    },
    goToLoginByPhone() {
      this.$$router.push({ name: "login" });
    },
  },
};
</script>
